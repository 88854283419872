<template>
  <div class="home page-border">
    <Header />
    <div class="home-title">
      <h1>
        <span>Innovazione</span> tecnologica <br />
        e <span>presidio</span> della filiera
      </h1>
    </div>

    <div class="cover"></div>
    <div class="fiori-section">
      <img src="../assets/home-fiore1.png" alt="" draggable="false" />
      <img src="../assets/home-fiore2.png" alt="" draggable="false" />
      <img src="../assets/home-fiore3.png" alt="" draggable="false" />
      <img src="../assets/home-fiore4.png" alt="" draggable="false" />
      <div>
        <h1>Passione</h1>
        <h1>Ricerca</h1>
        <h1>Innovazione</h1>
      </div>
    </div>

    <div class="column-txt container-fluid colonne">
      <div class="row">
        <div class="column col-sm-12 col-lg-3">
          <p>
            La Mediterranea beneficia dell'esclusiva mondiale per la
            coltivazione e la vendita delle nuove varietà selezionate e del
            privilegio di poter denominare dette selezioni con i nomi che
            richiamano i luoghi, la storia e le tradizioni siciliane (Taormina,
            Biscari, Cefalù).
          </p>
        </div>

        <div class="column col-sm-12 col-lg-3">
          <p>
            Oltre ad essere uno dei maggiori esportatori di crisantemo a livello
            internazionale, la Società Consortile Agricola La Mediterranea
            s.r.l. è anche una delle aziende floro vivaistiche siciliane più
            avanzate.
          </p>
        </div>
        <div class="column col-sm-12 col-lg-3">
          <p>
            La Società é oggi, l'unica ditta che in Italia adotta la
            sterilizzazione dei terreni con vapore d'acqua sull'intera
            estensione della superficie coltivata, una tecnica che ha permesso
            all'azienda di bandire l'utilizzo di fumiganti chimici e di
            realizzare la raccolta differenziata dei rifiuti in cinque
            categorie.
          </p>
        </div>
        <div class="column col-sm-12 col-lg-3">
          <p>
            Dal punto di vista dei mercati di sbocco, l'azienda si rivolge in
            primo luogo al mercato italiano in ogni area regionale mentre
            l'esportazione riguarda, in particolare la Russia, l'Olanda,
            l'Inghilterra e la Grecia. Il mercato più lontano ad oggi raggiunto,
            operando un trasporto su gommato di oltre 10 giorni è quello di
            Novosibirsk, in Siberia, ad oltre 3300 Km ad est di Mosca.
          </p>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Home",
  components: {
    Header,
    Footer,
  },

  mounted() {
    document.body.style.overflowY = "scroll";
    window.scrollTo(0, 0);
  },
};
</script>
