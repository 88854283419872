<template>
  <footer class="container-fluid" id="footer">
    <div class="row footer-menu">
      <div class="col-6">
        <img src="../assets/title-footer.svg" draggable="false" />
      </div>

      <div class="col-6">
        <a @click="navigate('/')">HOME</a>
        <a @click="navigate('/about')">CHI SIAMO</a>
        <!--         <a @click="navigate('/catalogo')">CATALOGO</a>
 -->
      </div>
    </div>

    <div class="row footer-contacts">
      <div class="col-12 col-lg-4">
        <a href="https://goo.gl/maps/GM72AhyMAgEfkskD9" target="_blank"
          ><p>LA MEDITERRANEA S.R.L.</p>
          <p>Società Consortile Agricola</p>
          <p>Contrada Rinelli, 2 - 97011 Acate</p></a
        >
      </div>

      <div class="col-12 col-lg-5">
        <p>gurrieri@lamediterraneasrl.com</p>
        <p>francesco.gurrieri@lamediterraneasrl.com</p>
        <p>paola.gurrieri@lamediterraneasrl.com</p>
        <!-- <a href="tel:0932511970">0932 511970</a> -->
      </div>
      <div class="col-12 col-lg-3">
        <a href="tel:0932511970">0932 511970</a>
        <p>Paola Gurrieri: <a href="tel:338 624 8799">338 624 8799</a></p>
        <p>Francesco Gurrieri: <a href="tel:339 319 8370">339 319 8370</a></p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",

  methods: {
    navigate(path) {
      if (this.$route.path == path) {
        window.scrollTo(0, 0);
      } else {
        this.$router.push(path);
      }
    },
  },
};
</script>

<style>
</style>