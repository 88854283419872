<template>
  <div class="side-menu">
    <div class="menu-btn">
      <div class="lang">
        <!-- <button style="color: #000"><p>IT/</p></button
        ><button style="color: #000"><p>EN</p></button> -->
      </div>
      <div class="close-menu">
        <img
          src="../assets/closemenu.svg"
          alt="close menu"
          @click="closeMenu()"
        />
      </div>
    </div>

    <div class="nav-links">
      <a id="link-home"><router-link to="/">1. Home</router-link></a>
      <a id="link-about"><router-link to="/about">2. About</router-link></a>
      <!--       <a id="link-cat"><router-link to="/catalogo">3. Catalogo</router-link></a>
 -->
      <a id="link-contacts"
        ><a href="#footer" @click="closeMenu()">3. Contatti</a></a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "SideMenu",

  props: ["isOpen"],

  methods: {
    closeMenu() {
      this.$emit("closeMenu");
    },
  },
};
</script>

<style>
</style>